<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_sms_settings')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card no-body>

            <b-card-header class="pb-50 mt-2">
                <h5>{{$t('label_sms_settings')}}</h5>
            </b-card-header>

            <b-card-body>

                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="PREFIX + '_FORM'"
                        tag="form"
                        class="p-2"
                >


                        <validation-provider
                                #default="validationProps"
                                :name="$t('label_subject')"
                                rules="required"
                        >
                            <b-form-group
                                    :label="$t('label_subject')"
                                    class="required"
                            >
                                <b-form-input
                                        v-model="itemData.code"
                                        :placeholder="$t('label_subject')"
                                        :state="getValidationState(validationProps)"
                                        trim
                                />

                                <b-form-invalid-feedback>
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_subject')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_subject')"
                                class="required"
                        >
                            <b-form-input
                                    v-model="itemData.code"
                                    :placeholder="$t('label_subject')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <div class="d-flex mt-3 justify-content-end">
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2"
                                  type="submit"
                        >
                            {{ $t('label_save') }}
                        </b-button>
                        <b-button @click.prevent="$router.go(-1)"
                                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                  type="button"
                                  variant="outline-secondary"

                        >{{ $t('label_cancel') }}
                        </b-button>
                    </div>
                </validation-observer>
            </b-card-body>
        </b-card>

    </div>
</template>

<script>

    import {
        BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
        BFormGroup,
        BFormInput, BFormInvalidFeedback, BFormTextarea

    } from 'bootstrap-vue'

    import dynamicItemsBlock from '@src/views/components/dynamicItemsBlock'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,
            BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
            BFormGroup,
            BFormInput, BFormInvalidFeedback,
            dynamicItemsBlock,
            ValidationProvider, ValidationObserver, BFormTextarea
        },
        props: ['selectOption'],

        data() {
            return {
                PREFIX: 'sms_setting',


                parentOption: {},

                itemData: {
                    code:'',
                }
            }

        },

        created() {

            this.getPageData();
        },

        methods: {

            getPageData(){
                //
                // this.async('get', '/' + this.PREFIX + 's/email_footer', {params:{}}, function(res){
                //     this.itemData.default_email_footer_text = res.data.default_email_footer_text;
                // });
            },

            onSubmit() {

                this.async('post', '/' + this.PREFIX  + 's/email_footer', this.itemData, function (resp) {
                    this.getPageData();
                });


            }

        }


    }
</script>

